import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import "./hamburgers.min.css";

class GameTab extends Component {
  state = {
    isActive: false
  }

  handleClick = () => {
    this.setState(state => ({ isActive: !state.isActive }));
  };

  render() {
    const {
      selectedGame,
      gameList
    } = this.props;
    const {
      isActive
    } = this.state;

    return (
      <>
        <div className='standings__game'>
          <div className="standings__game-button">
            <button onClick={this.handleClick} className={`hamburger hamburger--emphatic ${isActive ? "is-active" : ""}`} type='button'>
              <span className='hamburger-box'>
                <span className='hamburger-inner'></span>
              </span>
            </button>
          </div>
          <div className={`standings__game-tab ${isActive ? "is-active" : ""}`}>
            <div key='overall' className='standings__game-tab-item'>
              <Link to="/standings/" className={selectedGame === "Overall" ? "active" : ""}>Overall</Link>
            </div>
            {
              gameList.map(( game, i ) => {
                return (
                  <div key={i} className='standings__game-tab-item'>
                    <Link to={`/game/${game.replace(/[^A-Z0-9]/ig, "")}/`} className={selectedGame === game ? "active" : ""}>{game}</Link>
                  </div>
                );
              })
            }
          </div>
        </div>
        <h3 style={{
          position: "absolute",
          top: 12,
          left: 75,
        }}>{selectedGame}</h3>
      </>
    );
  }
}

GameTab.propTypes = {
  selectedGame: PropTypes.string.isRequired,
  gameList: PropTypes.array.isRequired
};

export default GameTab;
