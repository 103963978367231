import React from "react";
import { StaticQuery, graphql} from "gatsby";
import RankingTable from "../components/rankingTable";

import Layout from "../components/layout";

const Standings = () => (
  <Layout>
    <h1>STANDINGS</h1>
    <StaticQuery
      query={graphql`
          query playerQuery {
            allTournamentsJson {
              edges {
                node {
                  game
                  season
                  results {
                    team
                    name
                    points
                  }
                }
              }
            }
            country1:allStatisticsXlsxSheet1 {
              edges {
                node {
                   Country
                  Name
                }
              }
            }
            country2:allStatistics2XlsxSheet1 {
              edges {
                node {
                   Country
                  Name
                }
              }
            }
            flags:allFile(filter: {relativeDirectory: {eq: "flags"}}) {
              edges {
                node {
                  name
                  childImageSharp {
                    fixed(width: 80, height: 60) {
                      src
                    }
                  }
                }
              }
            }
          }
        `}
      render={data => {
        let gamesList = [];
        data.allTournamentsJson.edges.forEach((el) => {
          if(!gamesList.includes(el.node.game)) {
            gamesList.push(el.node.game);
          }
        });
        return (
          <RankingTable data={data} selectedGame="Overall" gameList={gamesList}>
          </RankingTable>
        );
      }}
    />
  </Layout>
);

export default Standings;
