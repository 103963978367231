const flagImage = function (country, images) {
  let activeImage = "";
  images.edges.filter( el => {
    if(country === el.node.name) {
      activeImage = el.node.childImageSharp.fixed.src;
    }

    return true;
  });
  return activeImage;
};

export default flagImage;