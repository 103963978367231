import React, {Component} from "react";
import PropTypes from "prop-types";
import { sort } from "./helpers/sort";
import flagImage from "./helpers/flagImage";
import { Link } from "gatsby";
import "../pages/standings.css";
import GameTab from "./gameTab";
import SeasonControl from "./SeasonControl";

class RankingTable extends Component {

  state = {
    search: "",
    season: 2,
  };

  handleChange(e) {
    this.setState({ search: e.target.value.toUpperCase() });
  }

  render() {
    const {
      data,
      selectedGame,
      gameList
    } = this.props;

    const { edges } = data.allTournamentsJson;
    const { search, season } = this.state;

    let players = [];
    let playersWithScores = [];
    let searchedPlayer = [];
    let spot = 1;
    let seasons = 0;
    edges.forEach(( el ) => { // Cycle every tournament
      if(el.node.season > seasons) {
        seasons = el.node.season;
      }
      if(el.node.season === season) {
        el.node.results.forEach(( player ) => { // Find players who have played in a tournament
          if(!players.includes(player.name)) {
            players.push(player.name);
          }
        });
      }
    });

    players.forEach(( player ) => { // Cycle every player
      let totalPoints = 0;
      let team;
      let country;
      let fullName;
      edges.forEach(( el ) => { // Assign score and country for each player
        if(el.node.season === season) {
          el.node.results.forEach(( place ) => {
            if(place.name === player) {
              totalPoints += Number(place.points);
              team = place.team;
              fullName = place.team ? place.team+' | '+place.name : place.name;
            }
          });
        }
        data[`country${season}`].edges.forEach(( e ) => {
          if(e.node.Name === fullName) {
            country = e.node.Country;
          }
        });
      });
      playersWithScores.push({name: player, score: totalPoints, team: team, country: country});
      
    });
    playersWithScores = sort("score", "DESC", playersWithScores);
    playersWithScores.forEach(( player ) => {
      player.id = spot;
      if(search) { // If user is searching for something
        if(player.name.toUpperCase().includes(search)) {
          searchedPlayer.push(player);
        }
      }
      spot++;
    });
    if(Object.entries(searchedPlayer).length > 0) {
      playersWithScores = searchedPlayer;
    }
    return (
      <>
        <SeasonControl onSeasonChange={number => this.setState({ season: number })} active={season} seasons={seasons} />
        <div className="body">
          <GameTab selectedGame={selectedGame} gameList={gameList} />
          {playersWithScores.length === 0 && (
              <span>There are no tournaments for this game in this season!</span>
          )}
          {playersWithScores.length > 0 && (
            <>
              <div className='search'>
                <input type='text' onChange={this.handleChange.bind(this)} placeholder='Search player name' />
              </div>
              <div className='standings__labels'>
                <span>Player</span>
                <span>Score</span>
              </div>
              {
                playersWithScores.map((player, i) => {
                  return (
                      <Link to={`/player/${player.name}/`} key={i} className='standings__link' style={{
                        background: `url(${flagImage(player.country, data.flags)}) no-repeat right center`,
                      }}>
                        <div className='standings__item'>
                          <div className='standings__spot'>#{player.id}</div>
                          <div className='standings__name'>{player.team &&
                          <span>{player.team} | </span>
                          }{player.name}</div>
                          <div className='standings__score'>{player.score}</div>
                        </div>
                      </Link>
                  );
                })
              }
            </>
          )}
        </div>
      </>
    );
  }
}

RankingTable.propTypes = {
  data: PropTypes.object.isRequired,
  selectedGame: PropTypes.string.isRequired,
  gameList: PropTypes.array.isRequired
};

export default RankingTable;
